@use 'sass:map';
@use '@angular/material' as mat;
@use '@dqn/style-kit' as dqn;

@mixin color() {
  .dqn-checkbox {
    // Base & Unchecked
    color: dqn.use-token(dqn.$token-color-text);

    .dqn-checkbox-icon-outer {
      background-color: dqn.use-token(dqn.$token-color-background-input);
      border: 2px solid dqn.use-token(dqn.$token-color-border-brand);
    }

    // Checked & Indeterminate
    &.dqn-checkbox-checked, &.dqn-checkbox-indeterminate {
      .dqn-checkbox-icon-inner {
        background-color: dqn.use-token(dqn.$token-color-background-brand-bold);
      }
    }

    // Indeterminate
    &.dqn-checkbox-indeterminate {
      .dqn-checkbox-icon-indeterminate {
        background-color: dqn.use-token(dqn.$token-color-background-input);
      }
    }

    &:not(.dqn-checkbox-disabled) {
      // Hovered
      &:hover:not(.dqn-checkbox-standalone) {
        background-color: dqn.use-token(dqn.$token-color-background-neutral-subtle-hovered);
      }

      // TODO: Define pressed state
      // Pressed
      &:active {
        background-color: dqn.use-token(dqn.$token-color-background-neutral-subtle-pressed);
        outline: 1px solid dqn.use-token(dqn.$token-color-border-pressed);

        //// Unchecked & Indeterminate (results in checked)
        //&:not(.dqn-checkbox-checked) {
        //  .dqn-checkbox-icon-outer {
        //    background-color: dqn.use-token(dqn.$token-color-background-neutral-subtle-pressed); // TODO: Replace mat.m2-get-color-from-palette($primary-palette, 500);
        //
        //    .dqn-checkbox-icon-inner {
        //      background-color: dqn.use-token(dqn.$token-color-background-brand-bold); // TODO: Replace mat.m2-get-color-from-palette($primary-palette, 500);
        //
        //      .dqn-checkbox-icon-indeterminate {
        //        background-color: dqn.use-token(dqn.$token-color-background-brand-bold); // TODO: Replace with defined value
        //      }
        //    }
        //  }
        //}
        //
        //// Checked (results in unchecked)
        //&.dqn-checkbox-checked {
        //  .dqn-checkbox-icon-inner {
        //    background-color: dqn.use-token(dqn.$token-color-background-neutral-subtle-pressed); // TODO: Replace with defined value
        //  }
        //}
      }
    }

    // Disabled
    &.dqn-checkbox-disabled {
      // Base & Unchecked
      color: dqn.use-token(dqn.$token-color-text-disabled);

      .dqn-checkbox-icon-outer {
        background-color: dqn.use-token(dqn.$token-color-background-input);
        border: 2px solid dqn.use-token(dqn.$token-color-border-disabled);
      }

      // Checked & Indeterminate
      &.dqn-checkbox-checked, &.dqn-checkbox-indeterminate {
        .dqn-checkbox-icon-inner {
          background-color: dqn.use-token(dqn.$token-color-background-brand-bold-disabled);
        }
      }

      // Indeterminate
      &.dqn-checkbox-indeterminate {
        .dqn-checkbox-icon-indeterminate {
          background-color: dqn.use-token(dqn.$token-color-background-input);
        }
      }
    }

    // Focused
    &:focus-within {
      outline: 1px solid dqn.use-token(dqn.$token-color-border-focused);
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  .dqn-checkbox {
    font-family: mat.m2-font-family($typography-config);

    // Medium label
    .dqn-checkbox-label {
      // TODO: Use dynamic value
      /* Paragraph/Medium/Regular */
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem; /* 150% */
    }

    // Small label
    &.dqn-checkbox-small {
      .dqn-checkbox-label {
        // TODO: Use dynamic value
        /* Paragraph/XSmall/Regular */
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem; /* 166.667% */
      }
    }

    // Large label
    &.dqn-checkbox-large {
      .dqn-checkbox-label {
        // TODO: Use dynamic value
        /* Paragraph/Large/Regular */
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.75rem; /* 155.556% */
      }
    }
  }
}

@mixin theme($theme) {
  @include color();

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
