@use 'sass:map';
@use '@angular/material' as mat;
@use '@dqn/style-kit' as dqn;

@mixin color() {
  // Group
  .dqn-option-group {
    color: dqn.use-token(dqn.$token-color-text);

    .dqn-option-group-label {
      background-color: dqn.use-token(dqn.$token-color-background-neutral);
    }
  }

  // Option
  .dqn-option {
    background-color: dqn.use-token(dqn.$token-color-background-neutral-subtlest);
    color: dqn.use-token(dqn.$token-color-text);

    .dqn-option-container {
      border-top: 1px solid dqn.use-token(dqn.$token-color-border-accent-gamma-light);

      .dqn-option-attributes {
        color: dqn.use-token(dqn.$token-color-text-accent-gamma);
      }
    }

    // Hovered & Focused
    &:hover, &.dqn-option-focused {
      background-color: dqn.use-token(dqn.$token-color-background-neutral-subtlest-hovered);

      .dqn-option-attributes {
        color: dqn.use-token(dqn.$token-color-text-accent-gamma-hovered);
      }
    }

    // Selected (Single)
    &.active:not(.dqn-option-multiple) {
      background: dqn.use-token(dqn.$token-color-background-accent-gamma-light);

      .dqn-option-attributes {
        color: dqn.use-token(dqn.$token-color-text-neutral-subtle);
      }
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  .dqn-option, .dqn-option-group {
    font-family: mat.m2-font-family($typography-config);

    //styleName: Paragraph/Small/Regular;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }

  .dqn-option {
    .dqn-option-attribute {

      //styleName: Paragraph/XSmall/Regular;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
    }
  }
}

@mixin theme($theme) {
  @include color();

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
