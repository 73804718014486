@use 'sass:map';
@use '@angular/material' as mat;
@use '@dqn/style-kit' as dqn;

@mixin color() {
  .dqn-form-field {
    .dqn-form-field-label {
      color: dqn.use-token(dqn.$token-color-text);
    }

    .dqn-form-field-wrapper {
      border: 2px solid dqn.use-token(dqn.$token-color-border-input);
      color: dqn.use-token(dqn.$token-color-text);
    }

    input::placeholder, textarea::placeholder {
      color: dqn.use-token(dqn.$token-color-text-neutral-subtle);
      opacity: 1; /* Firefox */
    }

    .dqn-form-field-info {
      color: dqn.use-token(dqn.$token-color-text-neutral-subtlest);
    }

    &:not(.dqn-form-field-disabled) {
      // Hovered form field
      .dqn-form-field-label:hover,
      .dqn-form-field-wrapper:hover {
        .dqn-form-field-wrapper {
          background: dqn.use-token(dqn.$token-color-background-neutral);
        }
      }

      // Focused form field
      &.dqn-focused {
        .dqn-form-field-wrapper {
          outline: none;
          border-color: dqn.use-token(dqn.$token-color-border-focused);
        }
      }

      // Invalid form field
      &.dqn-form-field-invalid {
        .dqn-form-field-wrapper {
          border-color: dqn.use-token(dqn.$token-color-border-danger);
        }

        .dqn-form-field-info.dqn-form-field-has-error-info {
          color: dqn.use-token(dqn.$token-color-text-neutral-bold);

          .dqn-form-field-info-icon {
            color: dqn.use-token(dqn.$token-color-text-danger);
          }

          .dqn-form-field-info-additional-texts {
            color: dqn.use-token(dqn.$token-color-text-danger);
          }
        }
      }
    }

    // Disabled form field
    &.dqn-form-field-disabled {
      .dqn-form-field-label {
        color: dqn.use-token(dqn.$token-color-text-disabled);
      }

      .dqn-form-field-wrapper {
        border-color: dqn.use-token(dqn.$token-color-border-disabled);
        background: dqn.use-token(dqn.$token-color-background-disabled);

        input::placeholder, textarea::placeholder {
          color: dqn.use-token(dqn.$token-color-text-disabled);
          opacity: 1; /* Firefox */
        }
      }
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  .dqn-form-field {
    font-family: mat.m2-font-family($typography-config);

    .dqn-form-field-label,
    .dqn-form-field-wrapper {
      // TODO: Use dynamic value
      /* Paragraph/Small/Regular */
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }

    .dqn-form-field-info {
      /* Paragraph/XSmall/Regular */
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
      letter-spacing: normal;
    }
  }
}

@mixin theme($theme) {
  @include color();

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
