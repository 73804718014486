
      /**
 * Do not edit directly
 * Generated on Tue, 28 Jan 2025 14:51:30 GMT
 */


      
      @use '../../helpers' as theme-helpers;
      
      @mixin use-theme($selector: null) {
        @include theme-helpers.load-theme($selector) {
          @include use-tokens();
        }
      }
      
      @mixin use-tokens() {
          --dqn-color-background-neutral: #172b4d0f;
  --dqn-color-background-neutral-subtlest: #ffffff00;
  --dqn-color-background-neutral-subtlest-hovered: #f7f8f9ff;
  --dqn-color-background-neutral-subtlest-pressed: #dcdfe4ff;
  --dqn-color-background-neutral-subtlest-disabled: #172b4d08;
  --dqn-color-background-neutral-subtle: #172b4d08;
  --dqn-color-background-neutral-subtle-hovered: #172b4d0f;
  --dqn-color-background-neutral-subtle-pressed: #172b4d24;
  --dqn-color-background-neutral-subtle-disabled: #172b4d08;
  --dqn-color-background-neutral-bold: #44546fff;
  --dqn-color-background-neutral-bold-hovered: #2c3e5dff;
  --dqn-color-background-neutral-bold-pressed: #172b4dff;
  --dqn-color-background-neutral-bold-disabled: #172b4d08;
  --dqn-color-background-neutral-boldest: #2c3e5dff;
  --dqn-color-background-neutral-boldest-hovered: #172b4dff;
  --dqn-color-background-neutral-boldest-pressed: #091e42ff;
  --dqn-color-background-neutral-boldest-disabled: #172b4d08;
  --dqn-color-background-neutral-disabled: #172b4d08;
  --dqn-color-background-neutral-hovered: #172b4d24;
  --dqn-color-background-neutral-pressed: #172b4d4f;
  --dqn-color-background-neutral-clear: #ffffffff;
  --dqn-color-background-success: #dcf7cfff;
  --dqn-color-background-success-hovered: #baee9fff;
  --dqn-color-background-success-pressed: #97e670ff;
  --dqn-color-background-success-disabled: #58c3227d;
  --dqn-color-background-success-bold: #234e0eff;
  --dqn-color-background-success-bold-hovered: #122707ff;
  --dqn-color-background-success-bold-pressed: #234e0eff;
  --dqn-color-background-success-bold-disabled: #58c3227d;
  --dqn-color-background-danger: #fbcbcbff;
  --dqn-color-background-danger-hovered: #f79797ff;
  --dqn-color-background-danger-pressed: #f36363ff;
  --dqn-color-background-danger-disabled: #d411117d;
  --dqn-color-background-danger-bold: #aa0e0eff;
  --dqn-color-background-danger-bold-hovered: #7f0a0aff;
  --dqn-color-background-danger-bold-pressed: #550707ff;
  --dqn-color-background-danger-bold-disabled: #d411117d;
  --dqn-color-background-warning: #ffeed1ff;
  --dqn-color-background-warning-hovered: #ffdda3ff;
  --dqn-color-background-warning-pressed: #ffcc75ff;
  --dqn-color-background-warning-disabled: #ffab1a7d;
  --dqn-color-background-warning-bold: #a86b00ff;
  --dqn-color-background-warning-bold-hovered: #704700ff;
  --dqn-color-background-warning-bold-pressed: #382400ff;
  --dqn-color-background-warning-bold-disabled: #ffab1a7d;
  --dqn-color-background-brand-bold: #000f40ff;
  --dqn-color-background-brand-bold-hovered: #303c62ff;
  --dqn-color-background-brand-bold-pressed: #303c62ff;
  --dqn-color-background-brand-bold-disabled: #000f407d;
  --dqn-color-background-brand-boldest: #00041cff;
  --dqn-color-background-brand-boldest-hovered: #00082aff;
  --dqn-color-background-brand-boldest-pressed: #010a2bff;
  --dqn-color-background-brand-boldest-disabled: #000f407d;
  --dqn-color-background-brand-subtle: #1a2753ff;
  --dqn-color-background-brand-subtle-hovered: #0c1d56ff;
  --dqn-color-background-brand-subtle-pressed: #000f40ff;
  --dqn-color-background-brand-subtle-disabled: #000f4024;
  --dqn-color-background-brand-subtlest: #6075b6ff;
  --dqn-color-background-brand-subtlest-hovered: #303c62ff;
  --dqn-color-background-brand-subtlest-pressed: #1a2753ff;
  --dqn-color-background-brand-subtlest-disabled: #000f4008;
  --dqn-color-background-accent-alpha: #61ffa0ff;
  --dqn-color-background-accent-alpha-hovered: #59ff98ff;
  --dqn-color-background-accent-alpha-pressed: #4fff8eff;
  --dqn-color-background-accent-alpha-disabled: #61ffa07d;
  --dqn-color-background-accent-alpha-light: #cfffe2ff;
  --dqn-color-background-accent-alpha-light-hovered: #79ffaeff;
  --dqn-color-background-accent-alpha-light-pressed: #61ffa0ff;
  --dqn-color-background-accent-alpha-light-disabled: #61ffa024;
  --dqn-color-background-accent-delta: #94f2ffff;
  --dqn-color-background-accent-delta-hovered: #8cf0ffff;
  --dqn-color-background-accent-delta-pressed: #81eeffff;
  --dqn-color-background-accent-delta-disabled: #94f2ff7d;
  --dqn-color-background-accent-delta-light: #dffbffff;
  --dqn-color-background-accent-delta-light-hovered: #a4f4ffff;
  --dqn-color-background-accent-delta-light-pressed: #94f2ffff;
  --dqn-color-background-accent-delta-light-disabled: #94f2ff24;
  --dqn-color-background-accent-gamma: #9fb4ffff;
  --dqn-color-background-accent-gamma-hovered: #97adffff;
  --dqn-color-background-accent-gamma-pressed: #8da4ffff;
  --dqn-color-background-accent-gamma-disabled: #9fb4ff7d;
  --dqn-color-background-accent-gamma-light: #d5deffff;
  --dqn-color-background-accent-gamma-light-hovered: #adbfffff;
  --dqn-color-background-accent-gamma-light-pressed: #9fb4ffff;
  --dqn-color-background-accent-gamma-light-disabled: #9fb4ff24;
  --dqn-color-background-accent-beta: #ff9fb6ff;
  --dqn-color-background-accent-beta-hovered: #ff97afff;
  --dqn-color-background-accent-beta-pressed: #ff7596ff;
  --dqn-color-background-accent-beta-disabled: #ff9fb67d;
  --dqn-color-background-accent-beta-light: #ffd5dfff;
  --dqn-color-background-accent-beta-light-hovered: #ffadc1ff;
  --dqn-color-background-accent-beta-light-pressed: #ff9fb6ff;
  --dqn-color-background-accent-beta-light-disabled: #ff9fb624;
  --dqn-color-background-accent-epsilon: #dbff00ff;
  --dqn-color-background-accent-epsilon-hovered: #d7ff00ff;
  --dqn-color-background-accent-epsilon-pressed: #d2ff00ff;
  --dqn-color-background-accent-epsilon-disabled: #dbff007d;
  --dqn-color-background-accent-epsilon-light: #f4ffb3ff;
  --dqn-color-background-accent-epsilon-light-hovered: #edff80ff;
  --dqn-color-background-accent-epsilon-light-pressed: #e6ff4dff;
  --dqn-color-background-accent-epsilon-light-disabled: #dbff0024;
  --dqn-color-background-disabled: #172b4d08;
  --dqn-color-background-information: #cce0ffff;
  --dqn-color-background-information-bold: #0c66e4ff;
  --dqn-color-background-information-bold-hovered: #0055ccff;
  --dqn-color-background-information-bold-pressed: #09326cff;
  --dqn-color-background-information-bold-disabled: #388bff7d;
  --dqn-color-background-information-hovered: #85b8ffff;
  --dqn-color-background-information-pressed: #579dffff;
  --dqn-color-background-information-disabled: #388bff7d;
  --dqn-color-background-input: #ffffffff;
  --dqn-color-background-input-hovered: #172b4d08;
  --dqn-color-background-input-pressed: #ffffffff;
  --dqn-color-elevation-surface: #f5f7ffff;
  --dqn-color-elevation-surface-higher: #ffffffff;
  --dqn-color-elevation-surface-higher-hovered: #f7f8f9ff;
  --dqn-color-elevation-surface-higher-pressed: #dcdfe4ff;
  --dqn-color-elevation-surface-highest: #ffffffff;
  --dqn-color-elevation-surface-highest-hovered: #f7f8f9ff;
  --dqn-color-elevation-surface-highest-pressed: #dcdfe4ff;
  --dqn-color-elevation-surface-lower: #dcdfe4ff;
  --dqn-color-elevation-surface-lower-hovered: #b3b9c4ff;
  --dqn-color-elevation-surface-lower-pressed: #8590a2ff;
  --dqn-color-elevation-surface-lowest: #b3b9c4ff;
  --dqn-color-elevation-surface-lowest-hovered: #8590a2ff;
  --dqn-color-elevation-surface-lowest-pressed: #758195ff;
  --dqn-color-elevation-surface-hovered: #f7f8f9ff;
  --dqn-color-elevation-surface-pressed: #dcdfe4ff;
  --dqn-color-elevation-surface-overlay: #ffffffff;
  --dqn-color-elevation-surface-overlay-hovered: #f7f8f9ff;
  --dqn-color-elevation-surface-overlay-pressed: #dcdfe4ff;
  --dqn-color-elevation-surface-dim: #172b4d7d;
  --dqn-color-elevation-surface-dim-subtlest: #ffffff7d;
  --dqn-color-elevation-surface-dim-subtle: #172b4d08;
  --dqn-color-text: #000f40ff;
  --dqn-color-text-accent-epsilon: #dbff00ff;
  --dqn-color-text-accent-epsilon-disabled: #dbff007d;
  --dqn-color-text-accent-epsilon-hovered: #d7ff00ff;
  --dqn-color-text-accent-epsilon-pressed: #d2ff00ff;
  --dqn-color-text-accent-alpha: #61ffa0ff;
  --dqn-color-text-accent-alpha-disabled: #61ffa07d;
  --dqn-color-text-accent-alpha-hovered: #59ff98ff;
  --dqn-color-text-accent-alpha-pressed: #4fff8eff;
  --dqn-color-text-accent-alpha-light: #cfffe2ff;
  --dqn-color-text-accent-alpha-light-disabled: #61ffa024;
  --dqn-color-text-accent-alpha-light-hovered: #79ffaeff;
  --dqn-color-text-accent-alpha-light-pressed: #61ffa0ff;
  --dqn-color-text-accent-delta: #94f2ffff;
  --dqn-color-text-accent-delta-disabled: #94f2ff7d;
  --dqn-color-text-accent-delta-hovered: #8cf0ffff;
  --dqn-color-text-accent-delta-pressed: #81eeffff;
  --dqn-color-text-accent-delta-light: #dffbffff;
  --dqn-color-text-accent-delta-light-disabled: #94f2ff24;
  --dqn-color-text-accent-delta-light-hovered: #94f2ffff;
  --dqn-color-text-accent-delta-light-pressed: #8cf0ffff;
  --dqn-color-text-accent-gamma: #9fb4ffff;
  --dqn-color-text-accent-gamma-disabled: #9fb4ff7d;
  --dqn-color-text-accent-gamma-hovered: #8da4ffff;
  --dqn-color-text-accent-gamma-pressed: #839cffff;
  --dqn-color-text-accent-gamma-light: #d5deffff;
  --dqn-color-text-accent-gamma-light-disabled: #9fb4ff24;
  --dqn-color-text-accent-gamma-light-hovered: #9fb4ffff;
  --dqn-color-text-accent-gamma-light-pressed: #97adffff;
  --dqn-color-text-accent-beta: #ff9fb6ff;
  --dqn-color-text-accent-beta-disabled: #ff9fb67d;
  --dqn-color-text-accent-beta-hovered: #ff7596ff;
  --dqn-color-text-accent-beta-pressed: #f62356ff;
  --dqn-color-text-accent-beta-light: #ffd5dfff;
  --dqn-color-text-accent-beta-light-disabled: #ff9fb624;
  --dqn-color-text-accent-beta-light-hovered: #ff9fb6ff;
  --dqn-color-text-accent-beta-light-pressed: #ff97afff;
  --dqn-color-text-accent-epsilon-light: #e6ff4dff;
  --dqn-color-text-accent-epsilon-light-hovered: #dbff00ff;
  --dqn-color-text-accent-epsilon-light-pressed: #d7ff00ff;
  --dqn-color-text-accent-epsilon-light-disabled: #dbff0024;
  --dqn-color-text-danger: #d41111ff;
  --dqn-color-text-danger-disabled: #d411117d;
  --dqn-color-text-danger-hovered: #aa0e0eff;
  --dqn-color-text-danger-pressed: #7f0a0aff;
  --dqn-color-text-information: #388bffff;
  --dqn-color-text-information-disabled: #388bff7d;
  --dqn-color-text-information-hovered: #1d7afcff;
  --dqn-color-text-information-pressed: #0c66e4ff;
  --dqn-color-text-neutral-subtle: #44546fff;
  --dqn-color-text-neutral-subtle-hovered: #626f86ff;
  --dqn-color-text-neutral-subtle-pressed: #758195ff;
  --dqn-color-text-neutral-subtle-disabled: #172b4d7d;
  --dqn-color-text-neutral-subtlest: #626f86ff;
  --dqn-color-text-neutral-subtlest-hovered: #758195ff;
  --dqn-color-text-neutral-subtlest-pressed: #8590a2ff;
  --dqn-color-text-neutral-subtlest-disabled: #172b4d4f;
  --dqn-color-text-neutral-bold: #2c3e5dff;
  --dqn-color-text-neutral-bold-hovered: #44546fff;
  --dqn-color-text-neutral-bold-pressed: #626f86ff;
  --dqn-color-text-neutral-bold-disabled: #172b4d7d;
  --dqn-color-text-neutral-boldest: #091e42ff;
  --dqn-color-text-neutral-boldest-hovered: #172b4dff;
  --dqn-color-text-neutral-boldest-pressed: #091e42ff;
  --dqn-color-text-neutral-boldest-disabled: #172b4d7d;
  --dqn-color-text-hovered: #303c62ff;
  --dqn-color-text-pressed: #6075b6ff;
  --dqn-color-text-success: #58c322ff;
  --dqn-color-text-success-hovered: #479c1bff;
  --dqn-color-text-success-pressed: #357514ff;
  --dqn-color-text-success-disabled: #58c3227d;
  --dqn-color-text-warning: #ffab1aff;
  --dqn-color-text-warning-disabled: #ffab1a7d;
  --dqn-color-text-warning-hovered: #e08e00ff;
  --dqn-color-text-warning-pressed: #a86b00ff;
  --dqn-color-text-disabled: #172b4d4f;
  --dqn-color-text-on-danger: #2a0303ff;
  --dqn-color-text-on-danger-disabled: #d411117d;
  --dqn-color-text-on-danger-hovered: #7f0a0aff;
  --dqn-color-text-on-danger-pressed: #aa0e0eff;
  --dqn-color-text-on-information: #1c2b41ff;
  --dqn-color-text-on-information-disabled: #388bff7d;
  --dqn-color-text-on-information-hovered: #09326cff;
  --dqn-color-text-on-information-pressed: #0055ccff;
  --dqn-color-text-on-success: #122707ff;
  --dqn-color-text-on-success-disabled: #58c3227d;
  --dqn-color-text-on-success-hovered: #234e0eff;
  --dqn-color-text-on-success-pressed: #357514ff;
  --dqn-color-text-on-warning: #382400ff;
  --dqn-color-text-on-warning-disabled: #ffab1a7d;
  --dqn-color-text-on-warning-hovered: #704700ff;
  --dqn-color-text-on-warning-pressed: #a86b00ff;
  --dqn-color-text-on-bold: #ffffffff;
  --dqn-color-text-on-bold-hovered: #f7f8f9ff;
  --dqn-color-text-on-bold-pressed: #b3b9c4ff;
  --dqn-color-text-on-bold-disabled: #ffffff7d;
  --dqn-color-icon: #0c1d56ff;
  --dqn-color-icon-accent-epsilon: #dbff00ff;
  --dqn-color-icon-accent-epsilon-disabled: #dbff007d;
  --dqn-color-icon-accent-epsilon-hovered: #d7ff00ff;
  --dqn-color-icon-accent-epsilon-pressed: #d2ff00ff;
  --dqn-color-icon-accent-alpha: #61ffa0ff;
  --dqn-color-icon-accent-alpha-disabled: #61ffa07d;
  --dqn-color-icon-accent-alpha-hovered: #59ff98ff;
  --dqn-color-icon-accent-alpha-pressed: #4fff8eff;
  --dqn-color-icon-accent-alpha-light: #cfffe2ff;
  --dqn-color-icon-accent-alpha-light-disabled: #61ffa024;
  --dqn-color-icon-accent-alpha-light-hovered: #79ffaeff;
  --dqn-color-icon-accent-alpha-light-pressed: #61ffa0ff;
  --dqn-color-icon-accent-delta: #94f2ffff;
  --dqn-color-icon-accent-delta-disabled: #94f2ff7d;
  --dqn-color-icon-accent-delta-hovered: #8cf0ffff;
  --dqn-color-icon-accent-delta-pressed: #81eeffff;
  --dqn-color-icon-accent-delta-light: #dffbffff;
  --dqn-color-icon-accent-delta-light-disabled: #94f2ff24;
  --dqn-color-icon-accent-delta-light-hovered: #94f2ffff;
  --dqn-color-icon-accent-delta-light-pressed: #8cf0ffff;
  --dqn-color-icon-accent-gamma: #9fb4ffff;
  --dqn-color-icon-accent-gamma-disabled: #9fb4ff7d;
  --dqn-color-icon-accent-gamma-hovered: #8da4ffff;
  --dqn-color-icon-accent-gamma-pressed: #839cffff;
  --dqn-color-icon-accent-gamma-light: #d5deffff;
  --dqn-color-icon-accent-gamma-light-disabled: #9fb4ff24;
  --dqn-color-icon-accent-gamma-light-hovered: #9fb4ffff;
  --dqn-color-icon-accent-gamma-light-pressed: #97adffff;
  --dqn-color-icon-accent-beta: #ff9fb6ff;
  --dqn-color-icon-accent-beta-disabled: #ff9fb67d;
  --dqn-color-icon-accent-beta-hovered: #ff7596ff;
  --dqn-color-icon-accent-beta-pressed: #f62356ff;
  --dqn-color-icon-accent-beta-light: #ffd5dfff;
  --dqn-color-icon-accent-beta-light-disabled: #ff9fb624;
  --dqn-color-icon-accent-beta-light-hovered: #ff9fb6ff;
  --dqn-color-icon-accent-beta-light-pressed: #ff97afff;
  --dqn-color-icon-accent-epsilon-light: #e6ff4dff;
  --dqn-color-icon-accent-epsilon-light-hovered: #dbff00ff;
  --dqn-color-icon-accent-epsilon-light-pressed: #d7ff00ff;
  --dqn-color-icon-accent-epsilon-light-disabled: #dbff0024;
  --dqn-color-icon-danger: #ee2f2fff;
  --dqn-color-icon-danger-disabled: #d411117d;
  --dqn-color-icon-danger-hovered: #d41111ff;
  --dqn-color-icon-danger-pressed: #aa0e0eff;
  --dqn-color-icon-information: #579dffff;
  --dqn-color-icon-information-disabled: #388bff7d;
  --dqn-color-icon-information-hovered: #388bffff;
  --dqn-color-icon-information-pressed: #1d7afcff;
  --dqn-color-icon-neutral-subtle: #626f86ff;
  --dqn-color-icon-neutral-subtle-disabled: #172b4d7d;
  --dqn-color-icon-neutral-subtle-hovered: #758195ff;
  --dqn-color-icon-neutral-subtle-pressed: #000f40ff;
  --dqn-color-icon-neutral-subtlest: #758195ff;
  --dqn-color-icon-neutral-subtlest-disabled: #172b4d24;
  --dqn-color-icon-neutral-subtlest-hovered: #8590a2ff;
  --dqn-color-icon-neutral-subtlest-pressed: #b3b9c4ff;
  --dqn-color-icon-neutral-bold: #44546fff;
  --dqn-color-icon-neutral-bold-disabled: #172b4d4f;
  --dqn-color-icon-neutral-bold-hovered: #758195ff;
  --dqn-color-icon-neutral-bold-pressed: #8590a2ff;
  --dqn-color-icon-neutral-boldest: #172b4dff;
  --dqn-color-icon-neutral-boldest-disabled: #172b4d7d;
  --dqn-color-icon-neutral-boldest-hovered: #2c3e5dff;
  --dqn-color-icon-neutral-boldest-pressed: #44546fff;
  --dqn-color-icon-success: #75de40ff;
  --dqn-color-icon-success-disabled: #58c3227d;
  --dqn-color-icon-success-hovered: #58c322ff;
  --dqn-color-icon-success-pressed: #479c1bff;
  --dqn-color-icon-warning: #ffbc47ff;
  --dqn-color-icon-warning-disabled: #ffab1a7d;
  --dqn-color-icon-warning-hovered: #ffab1aff;
  --dqn-color-icon-warning-pressed: #e08e00ff;
  --dqn-color-icon-disabled: #000f404f;
  --dqn-color-icon-on-danger: #aa0e0eff;
  --dqn-color-icon-on-danger-disabled: #f36363ff;
  --dqn-color-icon-on-danger-hovered: #d41111ff;
  --dqn-color-icon-on-danger-pressed: #ee2f2fff;
  --dqn-color-icon-on-information: #0c66e4ff;
  --dqn-color-icon-on-information-disabled: #85b8ffff;
  --dqn-color-icon-on-information-hovered: #1d7afcff;
  --dqn-color-icon-on-information-pressed: #388bffff;
  --dqn-color-icon-on-success: #357514ff;
  --dqn-color-icon-on-success-disabled: #97e670ff;
  --dqn-color-icon-on-success-hovered: #479c1bff;
  --dqn-color-icon-on-success-pressed: #58c322ff;
  --dqn-color-icon-on-warning: #a86b00ff;
  --dqn-color-icon-on-warning-disabled: #ffcc75ff;
  --dqn-color-icon-on-warning-hovered: #ffab1aff;
  --dqn-color-icon-on-warning-pressed: #ffbc47ff;
  --dqn-color-icon-hovered: #303c62ff;
  --dqn-color-icon-pressed: #6075b6ff;
  --dqn-color-icon-on-bold: #ffffffff;
  --dqn-color-icon-on-bold-hovered: #f7f8f9ff;
  --dqn-color-icon-on-bold-pressed: #b3b9c4ff;
  --dqn-color-icon-on-bold-disabled: #ffffff7d;
  --dqn-color-border: #172b4d24;
  --dqn-color-border-information: #1d7afcff;
  --dqn-color-border-neutral-subtle: #8590a2ff;
  --dqn-color-border-neutral-subtlest: #dcdfe4ff;
  --dqn-color-border-neutral-bold: #758195ff;
  --dqn-color-border-neutral-boldest: #172b4dff;
  --dqn-color-border-success: #479c1bff;
  --dqn-color-border-danger: #aa0e0eff;
  --dqn-color-border-warning: #e08e00ff;
  --dqn-color-border-focused: #9fb4ffff;
  --dqn-color-border-disabled: #172b4d0f;
  --dqn-color-border-input: #172b4d24;
  --dqn-color-border-on-bold: #ffffffff;
  --dqn-color-border-accent-alpha: #79ffaeff;
  --dqn-color-border-accent-alpha-light: #e2fff0ff;
  --dqn-color-border-accent-beta: #ffadc1ff;
  --dqn-color-border-accent-beta-light: #ffcfdbff;
  --dqn-color-border-accent-gamma: #adbfffff;
  --dqn-color-border-accent-gamma-light: #cfdaffff;
  --dqn-color-border-accent-delta: #a4f4ffff;
  --dqn-color-border-accent-delta-light: #caf9ffff;
  --dqn-color-border-accent-epsilon: #e0ff26ff;
  --dqn-color-border-accent-epsilon-light: #edff80ff;
  --dqn-color-border-pressed: #9fb4ffff;
  --dqn-color-border-selected: #8da4ffff;
  --dqn-color-border-brand: #000f40ff;
  --dqn-color-link: #6075b6ff;
  --dqn-color-link-pressed: #303c62ff;
  --dqn-color-link-visited: #728cffff;
  --dqn-color-link-hovered: #303c62ff;
  --dqn-color-link-disabled: #000f404f;
      }
      