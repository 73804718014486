@use 'sass:map';
@use '@angular/material' as mat;
@use './scss/dqn-neutral-pill-theme' as dqn-neutral-pill-theme;
@use './scss/dqn-primary-pill-theme' as dqn-primary-pill-theme;
@use './scss/dqn-danger-pill-theme' as dqn-danger-pill-theme;
@use './scss/dqn-success-pill-theme' as dqn-success-pill-theme;
@use './scss/dqn-warning-pill-theme' as dqn-warning-pill-theme;
@use '@dqn/style-kit' as dqn;

@mixin color() {
  @include dqn-neutral-pill-theme.theme();
  @include dqn-primary-pill-theme.theme();
  @include dqn-danger-pill-theme.theme();
  @include dqn-success-pill-theme.theme();
  @include dqn-warning-pill-theme.theme();

  .dqn-pill {
    // Selected
    &.dqn-pill-selected {
      color: dqn.use-token(dqn.$token-color-text-on-bold);
      background-color: dqn.use-token(dqn.$token-color-background-brand-bold);
    }

    &:not(.dqn-pill-disabled) {
      &.dqn-pill-tangible, &.dqn-pill-close {
        // Pressed pill
        &:active {
          outline: 2px solid dqn.use-token(dqn.$token-color-border-pressed);
        }

        // Focused pill
        &:focus-within {
          outline: 2px solid dqn.use-token(dqn.$token-color-border-focused);
        }
      }

      // Focused pill
      &:focus-visible {
        outline: 2px solid dqn.use-token(dqn.$token-color-border-focused);
      }
    }

    // Disabled pill
    &.dqn-pill-disabled {
      // Selected
      &.dqn-pill-selected {
        color: dqn.use-token(dqn.$token-color-text-on-bold-disabled);
        background-color: dqn.use-token(dqn.$token-color-background-brand-bold-disabled);
      }
    }

    // Subtle
    &.dqn-pill-subtle {
      color: dqn.use-token(dqn.$token-color-text);
      background-color: dqn.use-token(dqn.$token-color-background-input);

      .dqn-pill-icon-prefix, &.dqn-icon-pill {
        color: dqn.use-token(dqn.$token-color-icon);
      }

      // Selected
      &.dqn-pill-selected {
        border: 2px solid dqn.use-token(dqn.$token-color-border-brand);
      }

      &:not(.dqn-pill-disabled) {
        &.dqn-pill-tangible, &.dqn-pill-close {
          // Hovered pill
          &:hover {
            background: dqn.use-token(dqn.$token-color-background-input-hovered);
          }

          // Pressed pill
          &:active {
            background: dqn.use-token(dqn.$token-color-background-input-pressed);
            outline: 2px solid dqn.use-token(dqn.$token-color-border-pressed);
          }

          // Focused pill
          &:focus-within {
            outline: 2px solid dqn.use-token(dqn.$token-color-border-focused);
          }
        }

        &:focus-visible {
          outline: 2px solid dqn.use-token(dqn.$token-color-border-focused);
        }
      }

      // Disabled pill
      &.dqn-pill-disabled {
        color: dqn.use-token(dqn.$token-color-text-disabled);
        background-color: dqn.use-token(dqn.$token-color-background-input);
        border: 2px solid dqn.use-token(dqn.$token-color-border-disabled);

        .dqn-pill-icon-prefix, &.dqn-icon-pill {
          color: dqn.use-token(dqn.$token-color-icon-disabled);
        }

        // Selected
        &.dqn-pill-selected {
          border: 2px solid dqn.use-token(dqn.$token-color-border-disabled);
          background-color: dqn.use-token(dqn.$token-color-background-neutral-subtlest-disabled);
        }
      }
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  .dqn-pill {
    font-family: mat.m2-font-family($typography-config);

    // Medium label
    &.dqn-pill-medium {
      // TODO: Use dynamic value
      /* Paragraph/Medium/Regular */
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;

      .dqn-pill-icon-prefix, fa-icon {
        font-size: 1.25rem;
        line-height: 1.25rem;
      }
    }

    // Small label
    &.dqn-pill-small {
      // TODO: Use dynamic value
      /* Paragraph/XSmall/Regular */
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;

      .dqn-pill-icon-prefix, fa-icon {
        font-size: 1rem;
        line-height: 1rem;
      }
    }
  }
}

@mixin theme($theme) {
  @include color();

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
