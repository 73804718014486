@use 'sass:map';
@use '@angular/material' as mat;
@use '@dqn/style-kit' as dqn;
@use './scss/dqn-primary-button-theme' as dqn-primary-button-theme;
@use './scss/dqn-secondary-button-theme' as dqn-secondary-button-theme;
@use './scss/dqn-danger-button-theme' as dqn-danger-button-theme;


@mixin color() {
  .dqn-button {
    &:focus-visible {
      // Focused button
      outline: 3px solid dqn.use-token(dqn.$token-color-border-focused);
    }
  }

  @include dqn-primary-button-theme.color();

  @include dqn-secondary-button-theme.color();

  @include dqn-danger-button-theme.color();
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  .dqn-button {
    font-family: mat.m2-font-family($typography-config);

    // Medium label
    .dqn-button-label {
      // TODO: Use dynamic value
      /* Paragraph/Small/Medium */
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.25rem; /* 142.857% */
    }
  }
}

@mixin theme($theme) {
  @include color();

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
