@use 'sass:map';
@use '@angular/material' as mat;
@use '@dqn/style-kit' as dqn;

@mixin theme() {
  .dqn-pill {
    color: dqn.use-token(dqn.$token-color-text);
    background-color: dqn.use-token(dqn.$token-color-background-neutral);

    .dqn-pill-icon-prefix, &.dqn-icon-pill {
      color: dqn.use-token(dqn.$token-color-icon);
    }

    &:not(.dqn-pill-disabled) {
      &.dqn-pill-tangible, &.dqn-pill-close {
        // Hovered pill
        &:hover {
          background: dqn.use-token(dqn.$token-color-background-neutral-hovered);
        }

        // Pressed pill
        &:active {
          background: dqn.use-token(dqn.$token-color-background-neutral-pressed);
        }
      }
    }

    // Disabled pill
    &.dqn-pill-disabled {
      color: dqn.use-token(dqn.$token-color-text-disabled);
      background-color: dqn.use-token(dqn.$token-color-background-neutral-disabled);

      .dqn-pill-icon-prefix, &.dqn-icon-pill {
        color: dqn.use-token(dqn.$token-color-icon-disabled);
      }
    }

    // Subtle
    &.dqn-pill-subtle {
      border: 2px solid dqn.use-token(dqn.$token-color-border-neutral-subtle);
    }
  }
}
