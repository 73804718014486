@use 'sass:map';
@use '@angular/material' as mat;
@use '@dqn/style-kit' as dqn;

@mixin theme() {
  .dqn-pill.dqn-pill-primary {
    color: dqn.use-token(dqn.$token-color-text-on-bold);
    background-color: dqn.use-token(dqn.$token-color-background-brand-bold);

    .dqn-pill-icon-prefix, &.dqn-icon-pill {
      color: dqn.use-token(dqn.$token-color-icon-on-bold);
    }

    &:not(.dqn-pill-disabled) {
      &.dqn-pill-tangible, &.dqn-pill-close {
        // Hovered pill
        &:hover {
          background: dqn.use-token(dqn.$token-color-background-brand-bold-hovered);
        }

        // Pressed pill
        &:active {
          background: dqn.use-token(dqn.$token-color-background-brand-bold-pressed);
        }
      }
    }

    // Disabled pill
    &.dqn-pill-disabled {
      color: dqn.use-token(dqn.$token-color-text-on-bold-disabled);
      background-color: dqn.use-token(dqn.$token-color-background-brand-bold-disabled);

      .dqn-pill-icon-prefix, &.dqn-icon-pill {
        color: dqn.use-token(dqn.$token-color-icon-on-bold-disabled);
      }
    }

    // Subtle
    &.dqn-pill-subtle {
      border: 2px solid dqn.use-token(dqn.$token-color-border-brand);
    }
  }
}
