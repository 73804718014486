@use 'sass:map';
@use '@angular/material' as mat;
@use '@dqn/style-kit' as dqn;

@mixin color() {
  .dqn-input {
    color: dqn.use-token(dqn.$token-color-text);

    &::placeholder {
      color: dqn.use-token(dqn.$token-color-text-neutral-subtle);
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  .dqn-input {
    font-family: mat.m2-font-family($typography-config);

    // TODO: Use dynamic value
    /* Paragraph/Small/Regular */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
}

@mixin theme($theme) {
  @include color();

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
