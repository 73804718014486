@use 'sass:map';
@use '@angular/material' as mat;
@use '@dqn/style-kit' as dqn;

@mixin color() {
  .dqn-tooltip {
    color: dqn.use-token(dqn.$token-color-text-on-bold);
    background-color: dqn.use-token(dqn.$token-color-background-brand-bold);

    &.dqn-tooltip-subtle {
      background-color: dqn.use-token(dqn.$token-color-background-input);
      border: 1px solid dqn.use-token(dqn.$token-color-border-brand);
      color: dqn.use-token(dqn.$token-color-text);
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  .dqn-tooltip {
    font-family: mat.m2-font-family($typography-config);

    // TODO: Use dynamic value
    /* Paragraph/XSmall/Regular */
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;

    // Medium label
    &.dqn-tooltip-medium {
      // TODO: Use dynamic value
      /* Paragraph/Small/Regular */
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
    }

    // Large label
    &.dqn-tooltip-large {
      // TODO: Use dynamic value
      /* Paragraph/Medium/Regular */
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
    }
  }
}

@mixin theme($theme) {
  @include color();

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
