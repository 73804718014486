@use 'sass:map';
@use '@angular/material' as mat;
@use '@dqn/style-kit' as dqn;

@mixin color() {
  dqn-key-value-table {
    .keys {
      .key {
        background: dqn.use-token(dqn.$token-color-background-neutral-subtle);
      }
    }

    .values {
      .value {
        background: dqn.use-token(dqn.$token-color-background-neutral);
      }
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);
}

@mixin theme($theme) {
  @include color();

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
