@use 'sass:map';
@use '@angular/material' as mat;
@use '@dqn/style-kit' as dqn;

@mixin color() {
  .dqn-dropdown {
    color: dqn.use-token(dqn.$token-color-text);

    .select-placeholder {
      color: dqn.use-token(dqn.$token-color-text-neutral-subtle);
    }
  }

  .dqn-dropdown-container {
    .dqn-select-panel {
      border: 2px solid dqn.use-token(dqn.$token-color-border-neutral-subtle);
      background: dqn.use-token(dqn.$token-color-background-input);

      &::-webkit-scrollbar-track {
        background: dqn.use-token(dqn.$token-color-background-neutral-subtle);
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: dqn.use-token(dqn.$token-color-background-brand-bold);
      }
    }

    .dqn-select-panel-border {
      background: dqn.use-token(dqn.$token-color-background-accent-gamma);
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  .dqn-dropdown {
    font-family: mat.m2-font-family($typography-config);

    // Medium label
    .dqn-dropdown-label {
      // TODO: Use dynamic value
      /* Paragraph/Medium/Regular */
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem; /* 150% */
    }

    // Small label
    &.dqn-dropdown-small {
      .dqn-dropdown-label {
        // TODO: Use dynamic value
        /* Paragraph/XSmall/Regular */
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem; /* 166.667% */
      }
    }

    // Large label
    &.dqn-dropdown-large {
      .dqn-dropdown-label {
        // TODO: Use dynamic value
        /* Paragraph/Large/Regular */
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.75rem; /* 155.556% */
      }
    }
  }
}

@mixin theme($theme) {
  @include color();

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
