@use '@angular/material' as mat;

// Font
$app-font-family: "Lato", sans-serif;

// Colors
$app-color-red: #6d0020;
$app-color-blue: #4a8ac9;
$app-color-green: #76b726;
$app-color-grey: #1a1b1c;
$app-color-grey-light: #878787;
$app-color-grey-background: #e9e9e9;
$app-color-grey-background-light: #f5f5f5;
$app-color-white: #ffffff;
$app-color-primary: #6d0020;
$app-color-accent: #4a8ac9;
$app-color-success: #34c759;
$app-color-warn: #ff3b30;
$app-color-neutral: #d9dbe2;

$app-color-primary: $app-color-red;

$mat-card-alert-color-basic: rgba($app-color-grey, 0.1);
$mat-card-alert-color-primary: rgba($app-color-red, 0.1);
$mat-card-alert-color-accent: rgba($app-color-blue, 0.1);
$mat-card-alert-color-success: rgba(green, 0.1);
$mat-card-alert-color-danger: rgba(red, 0.1);
$mat-card-alert-color-warn: rgba(yellow, 0.1);


$app-blue: (
        50: #e1e7e9,
        100: #b4c4c8,
        200: #829da4,
        300: #4f7680,
        400: #2a5864,
        500: #043b49,
        600: #033542,
        700: #032d39,
        800: #022631,
        900: #011921,
        A100: #5dc6ff,
        A200: #2ab5ff,
        A400: #00a0f6,
        A700: #0090dd,
        contrast: (
                50: #000000,
                100: #000000,
                200: #000000,
                300: #ffffff,
                400: #ffffff,
                500: #ffffff,
                600: #ffffff,
                700: #ffffff,
                800: #ffffff,
                900: #ffffff,
                A100: #000000,
                A200: #000000,
                A400: #ffffff,
                A700: #ffffff,
        ),
);

$app-green: (
        50: #eff6e5,
        100: #d6e9be,
        200: #bbdb93,
        300: #9fcd67,
        400: #8bc247,
        500: #76b726,
        600: #6eb022,
        700: #63a71c,
        800: #599f17,
        900: #46900d,
        A100: #d9ffc0,
        A200: #b9ff8d,
        A400: #9aff5a,
        A700: #8bff41,
        contrast: (
                50: #000000,
                100: #000000,
                200: #000000,
                300: #000000,
                400: #000000,
                500: #ffffff,
                600: #ffffff,
                700: #ffffff,
                800: #ffffff,
                900: #ffffff,
                A100: #000000,
                A200: #000000,
                A400: #000000,
                A700: #000000,
        ),
);

$app-orange: (
        50: #f9eae5,
        100: #efc9bd,
        200: #e4a692,
        300: #d98266,
        400: #d16745,
        500: #c94c24,
        600: #c34520,
        700: #bc3c1b,
        800: #b53316,
        900: #a9240d,
        A100: #ffdbd7,
        A200: #ffaea4,
        A400: #ff8171,
        A700: #ff6a58,
        contrast: (
                50: #000000,
                100: #000000,
                200: #000000,
                300: #000000,
                400: #000000,
                500: #ffffff,
                600: #ffffff,
                700: #ffffff,
                800: #ffffff,
                900: #ffffff,
                A100: #000000,
                A200: #000000,
                A400: #000000,
                A700: #000000,
        ),
);

$app-neutral-palette: (
        50 : #fff,
        100 : #fff,
        200 : #f8f8f8,
        300 : #f2f3f5,
        400 : #dfe0e6,
        500 : $app-color-neutral,
        600 : #c5c7cc,
        700 : #858585,
        800 : #cacdd6,
        900 : #343434,
        A100 : #ffffff,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #000000,
                600 : #000000,
                700 : #000000,
                800 : #000000,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

$palettes: (
        'primary': $app-blue,
        'accent': $app-orange,
        'warn': mat.$m2-red-palette,
        'success': $app-green,
        'neutral': $app-neutral-palette,
);
