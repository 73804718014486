@use 'sass:map';
@use '@angular/material' as mat;
@use '@dqn/style-kit' as dqn;

@mixin color() {
  .dqn-button.dqn-danger-button {
    // Enabled button
    background: dqn.use-token(dqn.$token-color-background-danger-bold);
    color: dqn.use-token(dqn.$token-color-text-on-bold);

    &:not(:disabled) {
      &:active {
        // Pressed button
        background: dqn.use-token(dqn.$token-color-background-danger-bold-pressed);
        outline: 3px solid dqn.use-token(dqn.$token-color-border-pressed);
      }

      &:hover:not(:active) {
        // Hovered button
        background: dqn.use-token(dqn.$token-color-background-danger-bold-hovered);
      }
    }

    &:disabled {
      // Disabled button
      background: dqn.use-token(dqn.$token-color-background-danger-disabled);
      color: dqn.use-token(dqn.$token-color-text-disabled);
    }
  }
}
