@use 'sass:map';
@use '@angular/material' as mat;
@use '@dqn/style-kit' as dqn;

@mixin color() {
  .dqn-combobox {
    color: dqn.use-token(dqn.$token-color-text);

    .dqn-combobox-input {
      color: dqn.use-token(dqn.$token-color-text);

      &::placeholder {
        color: dqn.use-token(dqn.$token-color-text-neutral-subtle);
        opacity: 1;
      }
    }

    // HINT: Should be replaced with dqn-button as soon as subtle variant is available
    .dqn-clear-button:focus-visible {
      outline: 3px solid dqn.use-token(dqn.$token-color-border-focused);
      outline-offset: 2px;
      border-radius: 0.25rem;
    }
  }

  .dqn-combobox-container {
    .dqn-combobox-panel {
      background: dqn.use-token(dqn.$token-color-background-input);
      border: 2px solid dqn.use-token(dqn.$token-color-border-accent-gamma);

      &::-webkit-scrollbar-track {
        background: dqn.use-token(dqn.$token-color-background-neutral-subtle);
        border-radius: 0.25rem;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 0.25rem;
        background: dqn.use-token(dqn.$token-color-background-brand-bold);
      }
    }

    .dqn-combobox-panel-border {
      background: dqn.use-token(dqn.$token-color-background-accent-gamma);
    }

    // Virtualize
    &[data-virtualize="true"] {
      .rx-virtual-scroll-element {
        &::-webkit-scrollbar-track {
          background: dqn.use-token(dqn.$token-color-background-neutral-subtle);
          border-radius: 0.25rem;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 0.25rem;
          background: dqn.use-token(dqn.$token-color-background-brand-bold);
        }
      }
    }
  }

}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  .dqn-combobox {
    font-family: mat.m2-font-family($typography-config);

    // TODO: Use dynamic value
    //styleName: Paragraph/Small/Regular;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;

  }
}

@mixin theme($theme) {
  @include color();

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
