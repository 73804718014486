@use 'sass:map';
@use '@angular/material' as mat;
@use '@dqn/style-kit' as dqn;

@use './components/dqn-key-value-table/dqn-key-value-table-theme' as key-value-table;


@mixin color() {
  @include key-value-table.color();

  .dqn-table {
    color: dqn.use-token(dqn.$token-color-text);

    // Header without background
    &:not(.dqn-table-header-background) thead tr th {
      background: dqn.use-token(dqn.$token-color-background-neutral-clear);
      border-top: 1px solid dqn.use-token(dqn.$token-color-border-neutral-subtle);
      border-bottom: 1px solid dqn.use-token(dqn.$token-color-border-neutral-subtle);

      &:first-child {
        border-left: 1px solid dqn.use-token(dqn.$token-color-border-neutral-subtle);
      }

      &:last-child {
        border-right: 1px solid dqn.use-token(dqn.$token-color-border-neutral-subtle);
      }
    }

    &.dqn-table-header-background thead tr th {
      background: dqn.use-token(dqn.$token-color-background-brand-bold);
      color: dqn.use-token(dqn.$token-color-text-on-bold);
    }

    tbody {
      .dqn-table-cell {
        background: dqn.use-token(dqn.$token-color-background-neutral);
      }

      tr:hover .dqn-table-cell {
        background: dqn.use-token(dqn.$token-color-background-neutral-hovered) !important;
      }
    }

    &.dqn-table-striped {
      tbody {
        tr:nth-child(2n+2) {
          .dqn-table-cell {
            background: dqn.use-token(dqn.$token-color-background-neutral-clear);
          }
        }
      }
    }

    &.dqn-table-loading {
      // Transparent overlay with loading indicator
      .loading {
        background: dqn.use-token(dqn.$token-color-elevation-surface-dim-subtlest);
        color: dqn.use-token(dqn.$token-color-icon);
      }
    }

    .dqn-table-content {
      &::-webkit-scrollbar-track {
        background: dqn.use-token(dqn.$token-color-background-neutral-subtle);
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: dqn.use-token(dqn.$token-color-background-brand-bold);
      }
    }
  }
}

@mixin typography($theme) {
  @include key-value-table.typography($theme);

  $typography-config: mat.m2-get-typography-config($theme);

  .dqn-table {
    font-family: mat.m2-font-family($typography-config);

    // TODO: Use dynamic value
    /* Paragraph/Small/Regular */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 150% */

    thead th {
      /* TODO: Use dynamic value */
      /* Paragraph/Medium/Medium */
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }
  }
}

@mixin theme($theme) {
  @include color();

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
